html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(238, 190, 190, 0.582),
    rgba(214, 207, 238, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(231, 186, 186, 0.678),
    rgba(171, 157, 240, 0.863)
  );

  --imp-text-color: #c770f0;
  --imp-text-col: #f51c64;
  --imp-text-co: #000000;
  --imp-text-c: #421e1e;
  --imp-text-w: #ffffff;
}

.white {
  color: var(--imp-text-w) !important;
}

.purple {
  color: var(--imp-text-color) !important;
}

.pink {
  color: var(--imp-text-col) !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.black {
  color: var(--imp-text-co) !important;
}

.brown {
  color: var(--imp-text-c) !important;
}

.center {
  justify-content: "center";
}

button:focus {
  box-shadow: none !important;
}

.padd {
  padding-top: 150px !important;
}

.paddd {
  padding-bottom: 100px !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccbbe9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #bb72e6a9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.1rem 2rem !important;
  font-size: 1.1rem !important;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.navbar-toggler {
  position: relative !important;
  background-color: #be50f4 !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #303030 !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}
.mobile {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 2rem !important;
    font-size: 0.5rem !important;
    background-color: #9d56b3 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .mobile {
    display: block;
  }
  .disable{
    display: none;
  }
  .left {
    align-items: flex-start;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 10vw; 
  width: auto;
  max-height: 3.4em; 
  max-width: 100%; 
}

/* Additional media queries for finer control */
@media (max-width: 768px) {
  .logo {
    height: 8vw; /* Smaller height for smaller screens */
  }
}

@media (max-width: 480px) {
  .logo {
    height: 6vw; /* Even smaller height for very small screens */
  }
}

.navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.title {
  width: fit-content;
  text-align: center;
  background: rgb(122, 3, 122);
  border-radius: 30px 30px;
  padding: 15px;
  width: fit-content;
  height: 60px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: flex;
  justify-content: center; /* Aligns icons horizontally */
  align-items: center; /* Aligns icons vertically in case sizes differ */
  gap: 100px; /* Adds space between icons */
}

.social-icons a {
  font-size: 30px; /* Adjusts the size of the icons */
  color: white; /* Icon color */
  text-decoration: none;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  position: relative !important;
  background: linear-gradient(-45deg, #695779, #8400ff);
  bottom: 0 !important;
  border-radius: 30px 30px 0px 0px;
  padding-top: 10px !important;
  /* padding-bottom: 8px !important ; */
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: start !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: start !important;
    font-size: smaller;
  }

  .footer-body {
    text-align: start !important;
    font-size: smaller;
  }
  .footer h3 {
    font-size: small;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }

}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

hr {
  border: #ffffff;
  height: 4px;
  background-color: white;
  margin: 20px 0;
}
/* --------- */
/* Projects */
/* --------- */
.course-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.course-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.course-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: rgb(0, 0, 0) !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.course-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  align-content: center;
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.about-card {
  padding: 100px 250px !important; /* For large screens */
  height: auto !important;
  width: auto !important;
}

.about-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: rgb(0, 0, 0) !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

/* Responsive Design */
@media (max-width: 991px) {
  /* For tablet devices */
  .about-card {
    padding: 80px 100px !important; /* Adjust padding */
  }
}

@media (max-width: 767px) {
  /* For mobile devices */
  .about-section {
    padding-top: 80px !important; /* Reduce top padding */
    padding-bottom: 20px !important;
  }

  .about-card {
    padding: 50px 20px !important; /* More compact padding for mobile */
  }

  .about-card-view {
    box-shadow: none !important; /* Optional: remove shadow on small screens */
    padding: 20px !important; /* Ensure padding inside the card is not too big */
  }
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}

.whatsapp-button-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

.whatsapp-icon {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  */
  border-radius: 5px; /* Optional: round the corners */
  transition: transform 0.2s, box-shadow 0.2s; /* Transition for hover effect */
}

.whatsapp-icon:hover {
  transform: scale(1.1); /* Scale up on hover */
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); Increase shadow on hover */
}
