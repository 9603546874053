/* enquiry.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f0f4f8; /* Light background for contrast */
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2rem;
  color: #333; /* Darker text color for headings */
  margin-bottom: 20px;
}

.box {
  background-color: white; /* White background for the form */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  padding: 40px;
}

.form-control {
  margin-bottom: 20px; /* Spacing between form controls */
}

input[type="email"],
input[type="text"],
textarea {
  width: 100%; /* Full width inputs */
  padding: 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded borders */
  font-size: 1rem; /* Font size for inputs */
}

textarea {
  min-height: 50px; /* Minimum height for textarea */
  resize: vertical; /* Allow vertical resizing */
}

button {
  background-color: #8400ff; 
  color: white;
  padding: 10px 20px; 
  border: none;
  border-radius: 5px; 
  font-size: 1rem; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

button:hover {
  background-color: #8400ff; 
}

@media (max-width: 600px) {
  .box {
    padding: 20px; /* Less padding on smaller screens */
  }

  h3 {
    font-size: 1.5rem; /* Smaller heading on mobile */
  }
}
