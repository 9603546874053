.contact-wrapper {
    padding: 9rem 0 5rem 0;
  }
  
  .container {
    margin-top: 1rem;
    text-align: center;
  }
  
  .contact-form {
    max-width: 50rem;
    margin: auto;
  }
  
  .contact-inputs {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  .contact-inputs input[type="submit"] {
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .contact-inputs input[type="submit"]:hover {
    background-color: #fff; /* Replace with desired color */
    border: 1px solid #000; /* Replace with desired color */
    color: #000; /* Replace with desired color */
    transform: scale(0.9);
  }
  